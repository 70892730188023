<template>
    <div class="Rankings">
		<Header></Header>
		<div :style="{'min-height':height + 'px'}">
			<div class="title">
				<h1>Top NFTs</h1>
				<p>按时间、专辑种类和其他统计数据排名。</p>
				<div>
					<el-row :gutter="20">
						<el-col :span="12">
							<el-select v-model="type" placeholder="请选择" @change="selectTypeBtn">
								<el-option label="最近24小时" value="0" />
								<el-option label="最近7天" value="1" />
								<el-option label="最近30天" value="2" />
								<el-option label="所有时间" value="3" />
							</el-select>
						</el-col>
						<el-col :span="12">
							<el-select v-model="cteId" placeholder="请选择" @change="selectCteIdBtn">
								<el-option label="全部" value="" />
								<el-option 
									v-for="(item,index) in cteIdRows" 
									:key="item.cteId" 
									:label="item.cteName" 
									:value="item.cteId" 
								/>
							</el-select>
						</el-col>
					</el-row>
				</div>
			</div>
			<div class="conBox">
				<div>
					<div class="conBoxttTop">
						<div class="Top_0 comm" style="text-align:left;">专辑</div>
						<div class="Top_1 comm">交易额</div>
						<div class="Top_2 comm">24小时上升率</div>
						<div class="Top_1 comm">7日上升率</div>
						<div class="Top_1 comm">底价</div>
						<div class="Top_1 comm">持有人数</div>
						<div class="Top_1 comm">作品数</div>
					</div>
					<div class="conBoxttList" v-for="(item,index) in rows" :key="index" @click="detailBtn(item.cnId)">
						<div class="Top_0 comm" style="text-align:left;">
							<div class="text" style="margin-right:15px;">{{item.numb}}</div>
							<div class="user">
								<img :src="item.cnLogo">
							</div>
							<div class="text">{{item.cnName}}</div>
						</div>
						<div class="Top_1 comm">
							<span style="color:red;">￥{{item.gmv}}</span>
						</div>
						<div class="Top_2 comm" style="color:rgb(52, 199, 123)" v-if="Number(item.lift24)>0">+{{item.lift24}}%</div>
						<div class="Top_2 comm" v-else-if="Number(item.lift24)==0">-</div>
						<div class="Top_2 comm" style="color:red" v-else>{{item.lift24}}%</div>
						<div class="Top_1 comm" style="color:rgb(52, 199, 123)" v-if="Number(item.lift7d)>0">+{{item.lift7d}}%</div>
						<div class="Top_1 comm" v-else-if="Number(item.lift7d)==0">-</div>
						<div class="Top_1 comm" style="color:red" v-else>{{item.lift7d}}%</div>
						<div class="Top_1 comm">
							<span v-if="Number(item.floorPrice) == 0">-</span>
							<span style="color:red;" v-else>￥{{item.floorPrice}}</span>
						</div>
						<div class="Top_1 comm">
							<el-tooltip
								class="box-item"
								effect="dark"
								:content="item.owers"
								placement="top"
							>	
								{{item.isOwers}}
							</el-tooltip>
						</div>
						<div class="Top_1 comm">
							<el-tooltip
								class="box-item"
								effect="dark"
								:content="item.items"
								placement="top"
							>	
								{{item.isItems}}
							</el-tooltip>
						</div>
					</div>
				</div>
				<div style="text-align:right;padding:8px;margin-top:10px;">
					<el-pagination
						@size-change="handleSizeChange"
						@current-change="handleCurrentChange"
						:current-page="currentPage"
						:page-sizes="[50, 100, 200, 300]"
						:page-size="pageSize"
						:total="total"
						layout="total, sizes, prev, pager, next, jumper"
					>
					</el-pagination>
				</div>
			</div>
		</div>
		<Footer></Footer>
    </div>
</template>
<script>
	import Header from "@/components/Header"
	import Footer from "@/components/Footer"
	export default {
		components: { Header,Footer },
		data() {
			return{
				height:'',
				type:'0',
				cteId:'',
				currentPage: 1,
				total: 0,
				pageSize:50,
				rows:[],
				cteIdRows:[]
			}
		},
		created(){
			this.setHeight();
			this.initData();
			this.getListType();
		},
		methods:{
			detailBtn(cnId){
				this.$router.push({
					path:'/Explore/Collection/'+ cnId,
				})
			},
			selectTypeBtn(val){
				this.type = val;
				this.initData();
			},
			selectCteIdBtn(val){
				this.cteId = val;
				this.initData();
			},
			initData(){
				let that = this;
				let type = that.type;
				let url = "";
				if(type =='0'){
					url = "stats/ho/getList";
				}
				if(type =='1'){
					url = "stats/wk/getList";
				}
				if(type =='2'){
					url = "stats/mh/getList";
				}
				if(type =='3'){
					url = "stats/ct/getList";
				}
				this.$axios({
					method:'post',
					headers: {
						token: ""
					},
					url: url,
					data:this.$qs.stringify({
						cteId: that.cteId,
						page: that.currentPage,
						limit: that.pageSize
					})
				})
				.then((res)=>{
					let rows = res.data.dataRoot;
					rows.forEach((item)=>{
						let items = item.items;
						let owers = item.owers;
						let gmv = item.gmv;
						let floorPrice = item.floorPrice;
						if(Number(floorPrice) >= 1000){
							item.floorPrice = (floorPrice / 1000).toFixed(2) + "k";
						}
						if(Number(gmv) >= 1000){
							item.gmv = (gmv / 1000).toFixed(2) + "k";
						}
						if(Number(items) >= 1000){
							item.isItems = (items / 1000).toFixed(2) + "k";
						}else{
							item.isItems = items;
						}
						if(Number(owers) >= 1000){
							item.isOwers = (owers / 1000).toFixed(2) + "k";
						}else{
							item.isOwers = owers;
						}
					})
					that.rows = rows;
					that.total = res.data.totalCounts;
				})
				.catch((err)=>console.log('请求失败',err))
			},
			getListType(){
				let that = this;
				this.$axios({
					method:'post',
					url:'nft/cte/getAll',
					headers: {
						token:""
					},
					data:this.$qs.stringify({
						
					})
				})
				.then((res)=>{
					this.cteIdRows = res.data.dataRoot;
				})
				.catch((err)=>console.log('请求失败',err))
			},
			handleSizeChange(val){
				this.pageSize = val;
				this.currentPage = 1;
				this.initData();
			},
			handleCurrentChange(val){
				this.currentPage = val;
				this.initData();
			},
			setHeight(){
				let h = document.documentElement.clientHeight || document.body.clientHeight;
				this.height = h - 277;
			}
		},
		beforeMount(){
			this.setHeight();
		},
		mounted(){
			window.addEventListener('resize', this.setHeight, false)
		},
		beforeDestroy(){
			window.removeEventListener('resize',this.setHeight, false)
		}
	};
</script>
<style scoped>
	.conBox{
		width:100%;
		max-width:1240px;
		margin:0px auto;
		padding:20px;
		box-sizing: border-box;
	}
	.conBoxttTop{
		height:50px;
		line-height: 50px;
		position: sticky;
		top: 72px;
		z-index: 1;
		background-color: rgb(255, 255, 255);
		box-shadow: rgb(255, 255, 255) -8px 0px 0px 0px, rgb(255, 255, 255) 8px 0px 0px 0px;
		border-bottom: 1px solid rgb(229, 232, 235);
		border-color: rgb(229, 232, 235);
		padding: 16px;
	}
	.conBoxttTop .comm,.conBoxttList .comm{
		height:50px;
		font-weight: 600;
		font-size: 16px;
		color: rgb(4, 17, 29);
		display: inline-block;
		vertical-align: text-bottom;
		text-align: center;
	}
	.conBoxttList{
		height:50px;
		line-height: 50px;
		background-color: rgb(255, 255, 255);
		box-shadow: rgb(255, 255, 255) -8px 0px 0px 0px, rgb(255, 255, 255) 8px 0px 0px 0px;
		border-bottom: 1px solid rgb(229, 232, 235);
		border-color: rgb(229, 232, 235);
		padding: 16px;
	}
	.Top_0{
		width: 375px;
		text-overflow: ellipsis;
		white-space: nowrap;
		font-weight: bold;
	}
	.Top_0>div{
		display: inline-block;
	}
	.Top_1{
		width: 125px;
		text-align: center;
	}
	.Top_2{
		width: 150px;
		text-align: center;
	}
	.Top_0 .num{
		margin-right: 8px;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		font-weight: bold;
		vertical-align: text-bottom;
	}
	.Top_0 .user{
		width:50px;
		height:50px;
		margin-right:8px;
	}
	.Top_0 .user img{
		width:50px;
		height:50px;
		border-radius:50%;
	}
	.Top_0 .text{
		vertical-align: text-bottom;
	}
	.Top_1 img{
		width:14px;
		height:14px;
		margin-right:8px;
	}
	.Rankings .title{
		width:800px;
		margin:0px auto;
		text-align:center;
	}
	.Rankings .title h1{
		font-weight: 600;
		font-size: 40px;
		letter-spacing: 0px;
		color: rgb(4, 17, 29);
		margin-bottom: 10px;
		margin-top: 40px;
	}
	.Rankings .title p{
		font-weight: 400;
		font-size: 16px;
		color: rgb(112, 122, 131);
		margin:20px 0px;
	}
	.Rankings .title .el-select{
		width:100%;
	}
</style>
